<template>
<div >
    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        margin="[10,10,10,10]"
        filename="Sale Statement"
        :paginate-elements-by-height="1000"
        :pdf-quality="2"
        pdf-format="A4"
        pdf-orientation="portrait"
        pdf-content-width="auto"
        :manual-pagination="true"
        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="col-md-12" v-if="viewno==1 || viewno==2">
                    <div class="row" v-if="isprint==1">
                        <div class="col-md-12 text-center">
                            <h3 class="page-header m-0 p-0">
                                V.S. DigiWorld
                            </h3>
                            <h5><u>Statement of Sales Account</u></h5>
                        </div>
                    </div>
                </div>
                <div class="panel-body" v-if="localviewno==1" style="background-color:white" >
                        <div class="info-box  bg-sheme  text-white" v-if="isprint==0">
                            <div class="info-icon bg-sheme-dark" >
                                <h5 style="margin-top:12px">Customer Details</h5>
                            </div>
                            <div class="info-details" style="padding-left:2px">
                                <div class="col-md-12 flex-between-row">
                                    <div class="flex-between-row">
                                        <h5 class="grd-bg-Dark">Customer ID -  </h5>
                                    <!-- </div>
                                    <div class="col-md-4"> -->
                                        <h5 style="margin-right:2px" class="custid ">
                                            <div v-if="edititem.customer!=null">
                                                <a href="#" @click="getcustomerpage()" class="text-white"> {{edititem.customer.generatedid}} </a>
                                                <span v-if="edititem.customer.verified==1">
                                                    <i class='bx bxs-badge-check' style="color:#0299f7;font-size: 17px;"></i>
                                                </span>
                                            </div>
                                        </h5>
                                        <h3 class="custid" v-if="false">{{edititem.generatedid}}</h3>
                                        <h3 class="custid">{{edititem.manualid}}</h3>
                                    </div>
                                    <div class="flex-between-row" >
                                        <div class="row "><h5 class="grd-bg-Dark"> Re-Payment Type - </h5>
                                            <h5 class="custid" style="background-color:#03745a;" v-if="edititem.customer!=null && edititem.customer.paymentmode!=null">
                                                {{edititem.customer.paymentmode.name}}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="float-right text-right flex-between-row" >
                                        <div class="row content-end"><h5 class="grd-bg-Dark"> Status - </h5><h5 class="custid" style="background-color:#03745a;">{{setaccounttype()}}</h5></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-column" style="font-size:12px" v-if="isprint==1">
                            <div class="col-md-5 flex-row">
                                <label for="" class="text-left text">Customer Id :</label>
                                <div class="text1" v-if="edititem.customer!=null"><label for="">{{edititem.customer.generatedid}}</label></div>
                            </div>
                            <div class="col-md-5 flex-row">
                                <label for="" class="text-left text">Lan Account No :</label>
                                <div class="text1"><label for="">{{edititem.manualid}}</label></div>
                            </div>
                        </div>
                        <div class="panel panel-cascade">
                            <div class="panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <table class="table table-bordered ">
                                        <tbody :class="isprint==0?'table-font':'table-font-print'">
                                            <tr>
                                                <td class="mw-30" style="padding-bottom:0px;width:25%;">
                                                    <!-- <div class="">Photo :</div> -->
                                                        <div class="text-left text">
                                                            <a :href="profileimageurl" target="_blank">
                                                                <img :src="profileimageurl" width="50px" height="50px"/>
                                                            </a>
                                                        </div>
                                                        <div class="text1" v-if="edititem.customer!=null"><label for="">{{ edititem.customer.name }}</label></div>
                                                </td>
                                                <td >
                                                    <div class="text-left text"  v-if="edititem.customer!=null">{{ getMasterLabelByType(edititem.customer.relationship_type,MASTER_RELATIONSHIP_TYPES) }}</div>
                                                    <div class="text1" v-if="edititem.customer!=null"><template v-if="edititem.customer!=null">{{edititem.customer.relation}}</template></div>
                                                </td>
                                                <td >
                                                    <div class="text ">Mobile No:</div><br>
                                                        <div class="" v-if="edititem.customer!=null"><display-mobiles :mobiles="edititem.customer.mobiles"></display-mobiles></div>
                                                </td>
                                                <td style="padding-bottom:0px">
                                                    <div class="text-left text ">Introducer:</div>
                                                    <div class="text1" v-if="edititem.customer!=null">
                                                        <template v-if="edititem.customer.referraluser!=null">
                                                        {{ edititem.customer.referraluser.name }}
                                                        {{ edititem.customer.referraluser.generatedid }}
                                                        <display-mobiles :mobiles="edititem.customer.referraluser.mobiles"></display-mobiles>
                                                    </template>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:30%;">
                                                    <div class="text-left text" style="width:30%">Address:</div>
                                                    <div class="text1" style="width:70%" v-if="edititem.customer!=null"><display-first-address :addresses="edititem.customer.addressess"></display-first-address></div>
                                                </td>
                                                
                                                <td >
                                                    <div class="text-left text ">Landmark:</div>
                                                    <div class="text1" v-if="edititem.customer.addressess!=null">
                                                        <!-- <span v-for="item in edititem.customer.addressess" :key="item.id"> -->
                                                            {{edititem.customer.addressess[0]?.landmark}}
                                                        <!-- </span> -->
                                                    </div>
                                                    <div class="text-left text ">Zone:</div>
                                                    <div class="text1" v-if="edititem.customer!=null">{{ getMasterLabelByType(edititem.customer.zoneid,MASTER_ZONE_TYPES) }}</div>
                                                    <div class="text-left text ">Location:</div>
                                                    <div class="text1" v-if="edititem.customer!=null">
                                                            <a :href="edititem.customer.location" target="_blank">{{edititem.customer.location}}</a>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div class="text-left text ">Customer Type:</div>
                                                    <div class="text1" v-if="edititem.customer!=null"><template v-if="edititem.customer.customer_type!=null">{{edititem.customer.customer_type}}</template></div>
                                                </td>
                                                <td>
                                                    <div class="text-left text ">Entry Date:</div>
                                                    <div class="text1" >
                                                        <template v-if="edititem.customer!=null">
                                                            <span v-if="edititem.customer.enteredby!=null">{{edititem.customer.enteredby.name}}</span><br>
                                                            <i class='bx bx-calendar-alt'></i>  {{moment(edititem.customer.created_at).format("DD-MM-YYYY")}}<br>
                                                            <i class='bx bx-time-five'></i> {{moment(edititem.customer.created_at).format("h:mm:ss A")}}
                                                        </template>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td scope="row" v-if="isprint==0">
                                                    <div>
                                                        <div class="text-left text ">First Document:</div>
                                                        <div class="text1">
                                                            <display-id-proof-front-back  :labelid="1" frontorback="1"></display-id-proof-front-back>     
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="text-left text mt-10">Second Document:</div>
                                                        <div class="text1 mt-10">
                                                            <display-id-proof-front-back  :labelid="1" frontorback="2"></display-id-proof-front-back>
                                                        </div>
                                                    </div>
                                                   
                                                    
                                                </td>
                                                <td v-if="isprint==0"> 
                                                    <div class="col-md-12 row">
                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="text-left text ">Third Doc.:</div>
                                                                <div class="text1 ">
                                                                    <display-id-proof-front-back :labelid="3" frontorback="1"></display-id-proof-front-back>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="text-left text mt-10">Fourth Doc.:</div>
                                                                <div class="text1 mt-10">
                                                                    <display-id-proof-front-back :labelid="4" frontorback="1"></display-id-proof-front-back>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 point_div">
                                                           
                                                                <div class="col-md-8 p-0">Reward Points :</div>
                                                                <div class="col-md-4 p-0">
                                                                    <span class="blink_span">{{ this.allpoints  ?? 0}}</span> 
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </td>
                                                <td>
                                                    <div class="text-left text ">Verify By:</div>
                                                    <div class="text1" >
                                                        <template v-if="edititem.customer.verifiedby!=null">
                                                            <span v-if="edititem.customer.verifiedby!=null">{{edititem.customer.verifiedby.name}}</span>
                                                            <br>
                                                            <span v-if="edititem.customer.verifiedby.mobiles!=null">
                                                                <display-mobiles :mobiles="edititem.customer.verifiedby.mobiles"></display-mobiles>
                                                            </span>
                                                        </template>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div class="text-left text ">Regestration Date</div>
                                                    <div class="text1" v-if="edititem.customer!=null">{{moment(edititem.customer.registation_date).format('DD-MM-YYYY')}}</div>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                      
                        <!-- <div  class="html2pdf__page-break" style="margin-bottom:20px;"></div> -->
                        <div class="info-box  bg-sheme  text-white mt-10" v-if="isprint==0">
                            <div class="info-icon bg-sheme-dark" style="padding:5px;">
                                <h5> Product Details</h5>
                            </div>
                            <div class="info-details">
                            
                            </div>
                        </div>
                        <div class="col-md-12 mt-10" v-if="isprint==1">
                            <h5> <i class="fa fa fa-user fa-2x" v-if="isprint==0"></i>Product Details</h5>
                        </div>
                        <div class="panel panel-cascade">
                            <div class="panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <table class="table table-sm table-inverse">
                                        <thead class="thead-inverse|thead-default">
                                            <tr :class="isprint==0?'table-font':'table-font-print'">
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Serial no.</th>
                                                <th>Company Name</th>
                                                <th>Modal Name</th>
                                                <th>Modal Number</th>
                                                <th>Sale Price</th>
                                            </tr>
                                            </thead>
                                            <tbody :class="isprint==0?'table-font':'table-font-print'" v-if="edititem.saletransaction.items!=null">
                                                <tr v-for="(item,index) in edititem.saletransaction.items" :key="item.id">
                                                    <td scope="row">{{index+1}}</td>
                                                    <td><span v-if="item.product!=null && item.product.category!=null">{{item.product.category.name}}</span></td>
                                                    <td>
                                                        <div v-if="item.barcodes.length>0">
                                                            <div v-for="val in item.barcodes" :key="val.id" >
                                                                <span>{{ val.barcode }}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><span v-if="item.product!=null && item.product.attribute!=null">
                                                        <div v-for="company in item.product.attribute" :key="company.id">
                                                        {{ getcompany(company.attribute_value_ids) }}
                                                        </div></span>
                                                    </td>
                                                    <td><span >{{item.product.name}}</span> </td>
                                                    <td><span v-if="item.product!=null">{{item.product.modelno}}</span> </td>
                                                    <td><span class="badge btn-success btn-xs" >{{item.finalamount}}</span> </td>
                                                </tr>
                                                
                                            </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="info-box  bg-sheme  text-white mt-10" v-if="isprint==0">
                            <div class="info-icon bg-sheme-dark" style="padding:5px;">
                                <h5>Bill Details</h5>
                            </div>
                            <div class="info-details">
                                <!-- <h4>Customer ID</h4> -->
                            </div>
                        </div>
                        <div class="col-md-12 mt-10" v-if="isprint==1">
                            <h5>Bill Details</h5>
                        </div>
                        <div class="panel panel-cascade">
                            <div class="panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <table class="table table-sm table-inverse">
                                        <thead class="thead-inverse|thead-default">
                                            <tr :class="isprint==0?'table-font':'table-font-print'">
                                                <th>Sale Date</th>
                                                <th>Cash Sale Price</th>
                                                <th>Grand Total</th>
                                                <th>Down Payment</th>
                                                <th>Discount</th>
                                                <th>Balance</th>
                                                <th>Emi Amount</th>
                                                <th>Paid Amount</th>
                                                <th>Remg Amount</th>
                                                <th>Followed by</th>
                                                <th v-if="isprint==0 && loginusercheck(147)">Followed by</th>
                                                <th v-if="loginusercheck(147)"></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th v-if="loginusercheck(148)">View</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody :class="isprint==0?'table-font':'table-font-print'">
                                            <tr>
                                                <td scope="row">
                                                    <span v-if="edititem.saletransaction!=null">{{moment(edititem.saletransaction.transactiondate).format('DD-MM-YYYY')}}</span>
                                                    <br>
                                                    <span>{{ edititem?.numberofemi }} Months Emi<br>Charges :</span>
                                                </td>
                                                <td>
                                                    <span>  {{edititem.billedamount}} </span> 
                                                    <br><br>
                                                     <span>  {{ getEmiCost(edititem) ?? 0 }}</span>
                                                </td>
                                                <td>{{ parseFloat(parseFloat(edititem.billedamount)+parseFloat(getEmiCost(edititem))).toFixed(2) }}</td>
                                                <td> {{edititem.downpayment ?? 0}}</td>
                                                <td> {{ edititem?.saletransaction?.discount ??0 }}</td>
                                                <td><span>  {{ edititem.totalloanamount ??0}}</span></td>
                                                <td>  {{edititem.emi}}</td>
                                                <td @dblclick="updatetobepaid(edititem)"  @keyup.enter="updatetobepaid(edititem)"> 
                                                    {{edititem.totalreceived}}
                                                    <input v-if="tobepaidfield" type="text" :placeholder="edititem.totalreceived" v-model="edititem.totalreceived">
                                                </td>
                                                <td  @dblclick="updateremaining(edititem)"  @keyup.enter="updateremaining(edititem)">
                                                    <span class="badge btn-danger btn-xs">{{edititem.remainingamount}}</span> 
                                                    <input v-if="remfield" type="text" :placeholder="edititem.remainingamount" v-model="edititem.remainingamount">
                                                </td>
                                                <td><div v-if="edititem.loanfollowed!=null">{{ edititem.loanfollowed.name}}</div></td>
                                                <td colspan="6" v-if="isprint==0 && loginusercheck(147)"> 
                                                    <select v-model="followedid" class="form-control form-cascade-control input-small" style="height:31px" >
                                                        <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                                                    </select>
                                                </td>   
                                                <td v-if="isprint==0 && loginusercheck(147)">  
                                                    <button v-if="!spinner" type="button" class="btn btn-view" style="background-color:#3086b9!important;border-radius:inherit;padding:2px;" @click="updatefollowed()"> Update</button>
                                                    <div v-if="spinner" style="width:50px" class="spinner"></div>
                                                </td>
                                                <td v-if="isprint==0 && loginusercheck(148)">    
                                                    <button type="button" class="btnsearch btn-view" style="padding:0px!important;width:59px!important;"  @click="followeddetails()"><i class="fa fa-exclamation"></i>  View</button>
                                                </td>
                                                
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-cascade">
                            <div class="col-md-12 panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <table class="table table-bordered ">
                                        <tbody :class="isprint==0?'table-font':'table-font-print'">
                                            <tr>
                                                
                                                <td >
                                                    <div class="text-left text ">Deal By:</div>
                                                    <div class="text1" ><template v-if="(edititem.saletransaction!=null && edititem.saletransaction.dealby!=null)">{{edititem.saletransaction.dealby.name}}</template></div>
                                                    <div class="text-left text ">Sale By:</div>
                                                    <div class="text1" ><template v-if="(edititem.saletransaction!=null && edititem.saletransaction.advisor!=null)">{{edititem.saletransaction.advisor.name}} <br/> {{ edititem.saletransaction.advisor.generatedid }}</template></div>
                                                </td>
                                                <!-- <td scope="row">
                                                    <div class="text-left text ">Zone:</div>
                                                    <div class="text1" v-if="edititem.customer!=null">{{ getMasterLabelByType(edititem.customer.zoneid,MASTER_ZONE_TYPES) }}</div>
                                                </td> -->
                                                <td>
                                                    <div class="text-left text ">Entry sale Date:</div>
                                                    <div class="text1" >
                                                        <template v-if="edititem.saletransaction!=null">
                                                            <span v-if="edititem.saletransaction.enteredby!=null">{{edititem.saletransaction.enteredby.name}}</span><br>
                                                            <i class='bx bx-calendar-alt'></i>  {{moment(edititem.saletransaction.created_at).format("DD-MM-YYYY")}}<br>
                                                            <i class='bx bx-time-five'></i> {{moment(edititem.saletransaction.created_at).format("h:mm:ss A")}}
                                                        </template>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div class="text-left text ">Delivered By:</div>
                                                    <div class="text1" ><template v-if="edititem.saletransaction!=null">{{edititem.saletransaction.saleby?.name}}</template></div>
                                                </td>
                                                <td scope="row" v-if="isprint==0">
                                                    <div class="text1">Statement:</div>
                                                    <div class="text-left text ">Invoice:</div>
                                                    
                                                    <div class="text1">
                                                        <button v-if="isprint==0"
                                                            type="button"
                                                            id="btnprint"
                                                            class="btn btn-xs pdf"
                                                            value="Go to Print"
                                                            @click="generateReport()"
                                                        >
                                                        <i class="bx bxs-file-pdf" style="font-size:25px"></i>
                                                        </button>
                                                    </div>
                                                    <div class="text-left text">
                                                        <!-- <i v-if="isprint==0" class="bx bx-receipt" @click="saveaspdf()" style="font-size:25px;cursor: pointer;"></i> -->
                                                        <i v-if="isprint==0" class="bx bx-receipt" @click="print2()" style="font-size:25px;cursor: pointer;"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="info-box  bg-sheme  text-white mt-10" v-if="isprint==0">
                            <div class="info-icon bg-sheme-dark" style="padding:5px;">
                                <h5>Emi Details</h5>
                            </div>
                        </div>
                        <div class="col-md-12 mt-10" v-if="isprint==1">
                            <h5> <i class="fa fa fa-user fa-2x" v-if="isprint==0"></i>Emi Details</h5>
                        </div>
                            <div class="panel panel-cascade">
                                <div class="panel-body">
                                    <div class="form-horizontal cascde-forms">
                                        <table class="table table-sm table-hover table-inverse table-striped display">
                                            <thead :class="isprint==0?'table-font':'table-font-print'">
                                                <tr>
                                                    <th>#</th>
                                                    <th>EMI NO</th>
                                                    <th>Emi Amount</th>
                                                    <th>Emi Date</th>
                                                    <th>Rec. Amount</th>
                                                    <th>Received Date</th>
                                                    <th>Receipt No</th>
                                                    <th>Collected By</th>
                                                    <th>Entry Date</th>
                                                    <th v-if="isprint==0">Message</th>
                                                    <th>Entry By</th> 
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <loan-emi-row v-for="(item,index) in edititem.emis" :index="index" v-bind:key="item.id" 
                                            :emiid="item.id" :showindex="showindex" :followed="edititem.followed" :isprint="isprint"></loan-emi-row>
                                        </table>
                                    </div>
                                </div>
                            </div>
                </div>
                <followed-by-details v-if="localviewno==2"></followed-by-details>
            </section>
        </section>
    </vue-html2pdf>
</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import FollowedByDetails from '../../components/followedby/FollowedByDetails.vue'
import LoanEmiRow from '../../components/loanemi/LoanEmiRow.vue'
import Constants from '../../components/utilities/Constants.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayIdProofFrontBack from '../../components/idproof/DisplayIdProofFrontBack.vue'
import VueHtml2pdf from 'vue-html2pdf'
export default {
    components: { FollowedByDetails,LoanEmiRow,DisplayFirstAddress,DisplayMobiles
                ,DisplayIdProofFrontBack,VueHtml2pdf
    },
    mixins:[Constants,FollowedByDetails],
    data(){
        return {
            moment:moment,
            localfollowedid:0,
            localviewno:1,
            showindex:-1,
            isprint:0,
            followedid:0,
            spinner:false,
            remfield:false,
            tobepaidfield:false,
            pdfid:[],
            allpoints:0
        }
    },
    mounted() {
        this.$store.dispatch("fetchattributes")
        this.$store.dispatch("fetchemployees")
        this.updateshownindex();
        this.$store.commit('assignidproofs',this.edititem.customer?.idproofs==null?[]:this.edititem.customer?.idproofs)
        this.fetchPoints()
    },
    watch:{
        edititem:function(){
            
        }
    },
    computed:{
        ...mapGetters([
		      'followedbydetails','viewno','edititem','employees','attributes'
	    ]),
        
        profileimageurl(){
            if(this.edititem.customer!=null){
                if(this.edititem.customer.profileimage!=null){
                    return this.edititem.customer.profileimage.fullurl;
                }
            }
            return '#'
        }
    },
    methods:{
        followedname(){
            let followname=[]
            if(this.edititem!=null){
                if(this.edititem.followed!=null){
                    followname=this.edititem.followed[this.edititem.followed.length-1];
                    if(followname!=null){
                        if(followname.user!=null){
                            return followname.user.name
                        }else{
                            return '--'
                        }
                    }else{
                        return '...'
                    } 
                }else{
                    return '...'
                }
            }else{
                return '---'
            }
        },
        fetchPoints() {
            let param = { customerid: this.edititem?.customer?.id }
            this.$http.post('api/customer/points/fetch', param)
                .then((response) => {
                    this.allpoints = response.data?.ledger?.balance ?? 0
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        updateshownindex(){
            let first=true;
            let unpaidminsequence=-1;
            this.edititem.emis.forEach(function(item){
                if(item.payment==null){
                    if(first){
                        first = false;
                        unpaidminsequence=item.sequence
                    } else if(unpaidminsequence>item.sequence){
                        unpaidminsequence = item.sequence
                        console.log(item.sequence)
                    }
                }
            })
            this.showindex=unpaidminsequence;
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        updatefollowed(){
            if(this.followedid!=0){
                this.spinner=true
                let param = {id:this.edititem.id,userid:this.followedid}
                this.$http.post('api/loan/followedby/createupdate',param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            }else{
                alert("Select value first")
            }
            
        },
        processDetailResponse(data){
            this.$store.commit('assignedititem',data)
            alert("Updated successfully")
            this.$notify({
               text:'followed Submitted Successfully',
               type:'success',
               top:'100',
               duration:'80000',
               speed:'800',
               closeOnClick:false,
               position:'center'
            })
            this.spinner=false
        },
        paidamount(){
            return 0;
        },
        remainingamount(){
            return 0;
        },
        followeddetails(){
            this.localviewno = 2
        },
        setaccounttype(){
            if(this.edititem.accountstatus==0){
                return "Active "
            }else if(this.edititem.accountstatus==1){
                return "Clear "
            }else if(this.edititem.accountstatus==2){
                return "Defaulter"
            }else if(this.edititem.accountstatus==3){
                return "Legal"
            }else if(this.edititem.accountstatus==4){
                return "Concesation"
            }else if(this.edititem.accountstatus==5){
                return "Closed"
            }
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        saveaspdf(){
            this.$store.commit('assignloadingstatus',1)
            let payload={transactionid:this.edititem.saletransactionid}
            window.axios.post('api/download/pdf',payload)
                .then(response=>this.processSequenceResponse(response.data))
                .catch(error=>console.log(error));
        },
        processSequenceResponse(data){
            console.log("processSequenceResponse")
            console.log(data)
            let payload={id:data.uploadpdfid}
            this.pdfid=data
            this.$store.commit('assignloadingstatus',0)
            window.axios.post('api/fetch/download/pdf',payload)
                    .then(response=>this.processFetchPdfResponse(response.data))
                    .catch(error=>console.log(error));
        },
        processFetchPdfResponse(data1){
            console.log("processFetchPdfResponse")
            console.log(data1)
            if(data1.isdownload==1){

                let payload={id:this.pdfid.uploadpdfid}
                window.axios.post('api/download/pdf/updatedeleted',payload)
                    .then(response=>this.processFetchtruePdfResponse(response.data))
                    .catch(error=>console.log(error));
                console.log("Pdf downloaded :"+this.pdfid.uploadpdfid)
                this.$store.commit('assignloadingstatus',0)
                // window.open(data1.filename, '_blank');
                window.location.href=data1.filename
                var fileURL = window.URL.createObjectURL(new Blob(data1.filename));
                console.log("fileurl :"+fileURL)
				var docUrl = document.createElement('x');
                docUrl.href =fileURL ;
                docUrl.setAttribute('download', 'file.pdf');
                document.body.appendChild(docUrl);
                docUrl.click();
            }else{
                setTimeout(() => {
                    console.log("not found")
					console.log("Pdf Id"+this.pdfid.uploadpdfid)
                    this.processCheckSequenceResponse(this.pdfid);
                }, 6000);
            }
        },
		processCheckSequenceResponse(data){
            console.log("processCheckSequenceResponse")
            console.log(data)
            let payload={id:data.uploadpdfid}
            // this.pdfid=data
            window.axios.post('api/fetch/download/pdf',payload)
                    .then(response=>this.processFetchPdfResponse(response.data))
                    .catch(error=>console.log(error));
        },
        updateremaining(edititem){
            if(this.loginusercheck(149)){
                console.log("actual :"+this.edititem.remainingamount)
                console.log("actual input:"+edititem.remainingamount)
                this.remfield=!this.remfield
                    let param={id:edititem.id,column:"remainingamount",value:this.edititem.remainingamount}
                    this.$http.post('api/loan/updatefield',param)
                        .then((response) =>this.processUpdateResponse(response.data))
                        .catch((err) => {
                        console.log(err)
                    });
            }else{
                alert('You Dont have right to Update' )
            }
            
            
        },
        processUpdateResponse(){
            this.$notify({
               text:'Remaining Amount updated',
               type:'success',
               top:'100',
               duration:'80000',
               speed:'800',
               closeOnClick:false,
               position:'center'
            })
        },
        updatetobepaid(edititem){
            if(this.loginusercheck(150)){
                this.tobepaidfield=!this.tobepaidfield
                let param={id:edititem.id,column:"totalreceived",value:this.edititem.totalreceived}
                this.$http.post('api/loan/updatefield',param)
                    .then((response) =>this.processUpdatePaidResponse(response.data))
                    .catch((err) => {
                    console.log(err)
                });
            }else{
                alert('You dont have right to Update')
            }
        },
        processUpdatePaidResponse(){
            this.$notify({
               text:'To be Paid Amount updated',
               type:'success',
               top:'100',
               duration:'80000',
               speed:'800',
               closeOnClick:false,
               position:'center'
            })
        },
        getcustomerpage(){
            if(this.$router.currentRoute.path=='/customerdetails'){
                this.$router.go(-2);
                setTimeout(() => {
                    this.$store.commit('assignedititem',this.edititem.customer)
                    this.$router.push({path:"/customerdetails"})
                }, 1000);
                
            }else{
                this.$store.commit('assignedititem',this.edititem.customer)
                this.$router.push({path:"/customerdetails"})
            }
        },
        getEmiCost(item) {
            let items = item?.saletransaction?.items
            let temp = 0
            let total = 0
            for(let i=0; i < items.length; i++){
                let json = JSON.parse(items[i].product?.emi_cost)?.emi_cost
                temp = parseFloat(temp) + parseFloat(json)
            }
            if(temp > 0){
                total = parseFloat(item.numberofemi) *  parseFloat(temp)
            }
            return parseFloat(total).toFixed(2) ?? 0;
        },
        print() {
			window.axios({
				url: 'https://tvln7ict8f.execute-api.ap-south-1.amazonaws.com/api/vsdigipdf/vsdigiinvoice1/pdf?transactionid='+this.edititem.saletransactionid,
				method: 'GET',
				responseType: 'blob',
			}).then((res) => {
				window.open(URL.createObjectURL(res.data));
			})
				.then(response => {
					var fileURL = window.URL.createObjectURL(new Blob([response.data]));
					var fileLink = document.createElement('a');
					fileLink.href = fileURL;
					this.$store.commit('assignloadingstatus',0)
					fileLink.setAttribute('download', 'file.pdf');
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch(error=>console.log(error));
		},
        async print2(item) {
			console.log("Trying to print the pdf")
			console.log(item)
			// this.spinner = true
			// this.dialog.show1 = false
			let payload = {transactionid:this.edititem.saletransactionid}

			
			await window.axios.post('https://ss.graddev.in/api/invoice1',payload,{
				responseType: 'blob',
			})
				.then(response => {
					this.spinner = false
					console.log(response.data)
					var fileURL = window.URL.createObjectURL(new Blob([response.data]));
					var fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute('download', this.edititem.saletransactionid+'.pdf');
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch(err=>{
					console.log(err)
					this.spinner = false
				});
		},
    }
}
</script>
<style scoped>
.table-font-print{
    font-size: 10px !important;
}
.table-font{
    font-size:14px !important;
}
.point_div {
    background: aqua;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

}
</style>